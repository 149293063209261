/*
 * VARIABLES
 */
$content-max-width = 980px


/*
 * FUNCTION
 */
mround(v)
	round(v - .01)
sqrt(x)
	math(x, 'sqrt')
/*
 * MIXIN
 */
size(w = auto, h = auto)
	width: w
	height: h

font-set(f_size, f_weight, f_color)
	color: f_color
	font-weight: f_weight
	font-size: f_size

newflag()
	.flexbox-image:after, .entry-content:after
		display block
		overflow visible
		position absolute
		top 0
		right 0
		size 0, 0
		border 25px transparent solid
		content ""
	&.withtext:after
		display block
		position absolute
		top 0
		right 0
		transform rotate(45deg)
		size 35px, 35px
		font-size 10pt
		font-weight bold
		line-height @height
		text-align center
		content "NEW"

newflagcolor(flagcolor, textcolor = white)
	.flexbox-image:after, .entry-content:after
		border-top-color txt
		border-right-color txt
	&.withtext:after
		color textcolor


colorwithhover(bg, txt)
	.entry-header, .entry-content
		background-color bg
		color txt
		a
			color txt
		a:hover, a:focus, a:active
			color bg
		&:hover
			background-color alpha(txt, 0.85)
			color bg
	&.new
		newflagcolor(txt, bg)

colorwithouthover(bg, txt)
	.entry-header, .entry-content
		background-color bg
		color txt
		a, a:hover, a:focus, a:active
			color txt
		&:hover
			background-color bg
			color txt

category-color(bg, txt)
	colorwithhover(bg, txt)
	&.label
		colorwithouthover(txt, bg)


clearfix()
	display block
	clear both
	content ""

// Centering
centered-box(mwidth = $content-max-width)
	max-width mwidth
	margin 0 auto

disc(size = 12px)
	position absolute
	size 12px, 12px
	margin 0 auto
	border-radius 50%
	content ""

pcwindow(width = $content-max-width + 1)
	@media (min-width width)
		{block}
smartphone(width = $content-max-width)
	@media (max-width width)
		{block}
wpadmin()
	@media screen and (max-width: 600px)
		{block}
