// JScrollPane
// 全体
.jspContainer
	overflow hidden
	postion relative
// スクロールバーを除いたエリア
.jsPane
	position absolute
// 縦スクロールバー
.jspVerticalBar
	position absolute
	top 0
	right 0
	size 11px, 100%
	background-color transparent
	.jspTrack
		background-image url('../img/bg-scroll-v-w11.png')
		background-repeat repeat-y
// 横スクロールバー
.jspHorizontalBar
	position absolute
	bottom 0
	left 0
	size 100%, 11px
	background-color transparent
// 背景
.jspTrack
	position relative
	background-color transparent
.jspDrag
	position relative
	background-color black
	top 0
	left 0
	size 11px, 11px
	border-radius 50%
	cursor pointer
	// min-height auto
.jspHorizontalBar .jspTrack
.jspHorizontalBar .jspDrag
	float left
	height 100%
