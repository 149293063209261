/*
 * VARIABLES
 */
// Size
$content-width = 980px
$footer-height = 20px

// Colors
$bg-color = white
$text-color-white = white
$text-color-black = #333333
$color-levels = #1c2850
$color-levels-inverted = #E3D7AF

.collapse
	display: none!important
	height: 0
.hidden
	visibility: hidden!important
	opacity: 0

.background
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0

.right
	text-align: right

.text-vertical
	display: inline-block
	writing-mode: vertical-rl
	-ms-writing-mode: tb-rl
	-webkit-writing-mode: vertical-rl
.thin-bracket-enlarge
	font-size: 1.5em
.thin-bracket-start-v
.thin-bracket-end-v
.thin-bracket-start
.thin-bracket-end
	display: inline-block
.thin-bracket-start
	margin-left: -0.5em
.thin-bracket-start-v
	margin-top: -0.5em
.thin-bracket-end
	margin-right: -0.5em
.thin-bracket-end-v
	margin-bottom: -0.5em

a.event-hover
	img
		transition: all ease-in-out .1s
a.event-hover:hover
	img
		filter: invert(100%) saturate(500%)
		-webkit-filter: invert(100%) saturate(500%)
	img.transparent
		background-color: white


/*
 * STYLES
 */
html
	-webkit-text-size-adjust: 100%
	-ms-text-size-adjust: 100%
	box-sizing: border-box

body
	margin: 0

button
	background-color: transparent
	border: none

*:link,*:visited
	color: black

.floatcontainer
	div
		float: left
	&:after
		display: block
		clearfix()


#page
	min-width: $content-width
	*
		// position: relative
		box-sizing: border-box

#pagetop
	size: 100% auto
	background-image: url('../img/top-img.jpg')
	background-repeat: no-repeat
	background-position: top center
	#pagetop-contents
		centered-box()
		overflow: visible
		size: 980px auto//1200px
		max-width: 980px
		// &:before
		// 	display: block
		// 	padding-top: 122.4%
		// 	content: ""
		#pagetop-inner
			position: relative
			size: 980px 800px
			.pagetop-content
				.pagetop-content-list
					position: relative
					margin: 0
					margin-bottom: 20px
					padding: 0
					list-style: none
					text-align: center
					&.top
						margin-top: 0px
					.pagetop-content-list-title
						margin-bottom: 10px
						font-set: 13px $font-regular #161d3f
						line-height: 1em
					.pagetop-content-list-item
						margin-bottom: 11px
						font-set: 20px $font-black #161d3f
						line-height: 1em
					.pagetop-content-list-note
						font-set: 12px $font-regular #161d3f
				.pagetop-content-text
					font-set: 18px $font-light black
					line-height: 2em
			#pagetop-content-staff
				absolute: top 170px left 784px
				size: 114px auto
				.pagetop-content-list
					text-align: left
					.pagetop-content-list-title
						margin-left: -5px
			#pagetop-content-cast
				absolute: top 170px left
				size: 114px auto
				.pagetop-content-list
					text-align: right
					.pagetop-content-list-title
						margin-right: -5px
					.pagetop-content-list-note
						margin-right: -5px
			#pagetop-content-logo-produce
				// absolute: top 470px left 60px
				absolute: top 800px left
				size: 140px 70px
			#pagetop-content-copy
				absolute: top 0px right
				size: 490px auto
				p
					margin: 0
					padding: 45px 0
		#pagetop-outer
			#pagetop-content-updates
				overflow: hidden
				position: relative
				float: right
				size: 490px auto
				margin: 0
				padding: 16px 24px
				border: 1px solid black
				color: black
				text-align: left
				.updates-title
					size: auto, 28px
					margin: 0
					font-size: 28px
					font-weight: $font-thin
					line-height: @height
				.update-list
					size 470px auto
					font-size 12px
					.update-date
						//
					.update-item
						margin 0 0 1em 0

#pagenav
	overflow hidden
	size 100%, auto
	background-color white
	&.fixed
		position fixed!important
		z-index 9
		&.top
			top 0
			background-size 1220px 70px
		&.bottom
			bottom $footer-height
	#inner-logo
		display block
		fixed top left
		z-index 555
		size 260px, 150px
		transition transform ease-in-out .3s
		&.offscreen
			transform translate3d(-(@width),0,0)
		img
			absolute top bottom left
			size 100%, auto
			margin auto 0
		// @media (max-width 1216px) and (min-width 980px)
		// 	size calc(100% - 780px), 150px//120px
		// 	// img
		// 	// 	top 45px
		// @media (max-width 980px)
		// 	size 200px, 150px
		// 	// img
		// 	// 	top 45px

	#top-nav
		size: 100%, 70px
		margin: 20px 0
		border-top: 1px solid black
		border-bottom: 1px solid black
		background-position center
		background-repeat repeat-x no-repeat
		transition: visibility .3s
			        , opacity ease-out .3s
		#top-nav-container
			overflow hidden
			position relative
			size $content-width, 70px
			centered-box $content-width
			text-align center
			.nav-list
				position relative
				// left 50%
				display inline-block
				size auto, 70px
				margin 0 auto
				padding 0
				.nav-list-item
					position relative
					display inline-block
					// left -50%
					float left
					margin: 0
					transition opacity .2s ease
					&:hover
						opacity: 0.5
	#inner-nav
		fixed: top
		size 100%, 150px
		min-width $content-width
		background-color: rgba(255,255,255,0.75);
		z-index: 111
		transition: visibility .3s
			        , opacity ease-out .3s
		#inner-nav-container
			relative: top right
			bottom 20px
			size 100%, 70px
			.nav-list
				poition relative
				float right
				size auto, 70px
				margin 0
				padding 0
				clear right
				.nav-list-item
					display inline-block
					size auto, 70px
					margin 0
					transition opacity .2s ease
					&:hover
						opacity: 0.5
					&.hidden
						display none

#side-nav
	display block
	fixed top 150px right
	size 150px auto
	transition transform ease-in-out .3s
	> *
		display block
		relative right
		margin 30px 0
	&.offscreen
		transform translate3d(@width,0,0)

spnw = 40vmin
#spnav
	position: fixed
	z-index: 999
	display block
	background-color: rgba(0,0,0,0)
	&.hidden
		display none
	#spnav-button
		position fixed
		right 2vmin
		bottom 6vmin
		size 16vmin, 16vmin
		background url('../img/spnav-button.svg') center center no-repeat
		background-size 16vmin 16vmin
		border-radius 50%
		box-shadow 0 0 0.5vmin 0.5vmin rgba(0,0,0,0.2)
	#spnav-container
		position fixed
		top 0
		right 0
		bottom 0
		overflow-x hidden
		overflow-y auto
		size spnw, auto
		background-color: #1c2850
		// background-image: linear-gradient(0deg, #f1951b, #f1951b 33%, #1c2850 66%, #1c2850);
		box-shadow 0 0 1vw 1vw rgba(0,0,0,0.5)
		transition transform .3s ease-in-out
		color white
		&.offscreen
			transform translate3d((spnw + 3vw), 0, 0)
		.spnav-list
			position relative
			list-style none
			margin 0
			padding 0
			.spnav-list-item
				position relative
				size 100%, 16vmin
				border-top    1px solid #999
				border-bottom 1px solid #333
				a, a:visited
					display block
					size 100%, 100%
					font-size 6vmin
					font-weight $font-regular
					text-align center
					line-height 16vmin
					text-decoration none
					color white
					text-shadow -2px -2px 1px #333,   0 -2px 1px #333,
					            -2px    0 1px #333, 1px  1px 1px #CCC
					// color rgba(255,255,255,0.5)

		.spnav-updates
			border-top 1px solid #999
			.updates-title
				font-size 3vmin
				margin-left 1em
			.update-list
				font-size 3vmin
				.update-date
					margin-left 1em
				.update-item
					margin-left 1em

#pagemain
	size 100%, auto
	color black
	section
		overflow: hidden
		min-height: 100vh
		padding: 150px 0
		background-clip border-box
		.main-catch
			position relative
			size $content-width, 200px
			centered-box $content-width
			img
				position: relative
				size: 100%, 100%
		.main-container
			position relative
			// overflow hidden
			size $content-width, auto
			centered-box $content-width
			.main-title
				absolute top left
			.main-content
				position relative
				size 650px, auto
				min-height 300px
				margin: 0 auto
				padding-top: 100px
				h1,h2,h3,h4,h5,h6
					size 100%, auto
					font-size 16px
					font-weight $font-regular
					border-bottom 1px solid black
					line-height 2em
				p
					margin: 0
		&.hidden
			display none

	#main-story
		font-set: 15px $font-regular black
		line-height 2em
	#main-cast
		#main-cast-content
			margin-left: 300px
			padding-top: 40px
			.main-cast-list
				margin: 60px 0
				padding: 0
				list-style: none
				.main-cast-list-type
					font-set: 15px $font-regular black
				.pickup
					margin: 1em 0
				.main-cast-list-item
					font-set: 30px $font-regular black
					line-height: 2em
					.main-cast-list-item-attr
						font-set: 15px $font-regular black
			.main-cast-list-supplementary
				font-set: 15px $font-regular black
				margin: 60px 0
	#main-staff
		#main-staff-content
			margin-left: 300px
			padding-top: 20px
			.main-staff-list
				margin: 80px 0
				padding: 0
				list-style: none
				.main-staff-list-role
					margin-top: 30px
					font-set: 15px $font-regular black
				.main-staff-list-item
					margin-left: 7px
					font-set: 30px $font-regular black
			.main-staff-list-supplementary
				font-set: 15px $font-regular black
	#main-schedule
		font-size 12px
		font-weight $font-light
		#main-schedule-content
			margin-left: 300px
			.compose-vertical-and-condensed
				// size: 380px 50px
				.tag
					font-set: 15px $font-thin black
					letter-spacing: 2px
					vertical-align: top
					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
						margin-top: -12px
				.catch
					font-set: 50px $font-thin black
					line-height: 1em
					vertical-align: top
			// #main-schedule-place
			// 	size: 375px 50px
			// #main-schedule-term
			// 	size: 370px 50px
			.main-schedule-access-description
				margin 0 auto 65px 20px
				font-set 15px $font-regular black
				a
					text-decoration: none
			.main-schedule-timetable
				width: 380px
				margin: 0 0 20px
				table-layout auto
				border-collapse collapse
				// margin 0 auto auto 0
				tbody
					tr
						size auto, 30px
						border-top 1px solid black
						border-bottom 1px solid black
						&:first-child
							border-top none
						td,th
							padding 0 20px
							text-align center
						th
							font-weight $font-light
							&.main-schedule-timetable-date
								text-align: left
			.seat-stock-description
				margin-left 20px
				font-weight $font-thin
				line-height: 2em
			.main-schedule-list
				margin: 80px 20px
				padding: 0
				list-style: none
				.main-schedule-list-type
					font-set 15px $font-regular black
				.main-schedule-list-item
					margin-left: 8px
					font-set: 20px $font-regular black
					line-height: 2em
	#main-ticket
		#main-ticket-content
			padding-top: 50px
			.ticket-title
				margin-top: 50px
			.ticket-normal-adv
			.ticket-normal-dos
			.ticket-event
				height: 80px
				margin: 20px auto 30px auto
				font-set: 80px $font-light black
				line-height: 80px
				// *
				// 	vertical-align: middle
				img
					margin-right: 15px
					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
						margin-top: -15px
				.ticket-price
					line-height: 80px
					vertical-align: top
				.ticket-price-tax
					font-set: 20px $font-light black
					vertical-align: middle
					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
						vertical-align: top!important
			.ticket-booking
				margin: 50px auto 30px auto
				input[type="submit"]
					float: left
					size: 220px 60px
					margin-right: 20px
					border: none
					background-image: url('../img/ticket-03.svg')
				label
					// display: block
					font-set: 15px $font-regular black
				select
					font-set: 15px $font-regular black
			.main-ticket-guide
				margin: 20px 0
				font-set: 15px $font-light black
	#main-special
		font-size 15px
		font-weight $font-regular


#pagefooter
	position fixed
	bottom 0
	z-index 9
	size 100%, $footer-height
	background-color white
	color $text-color-black
	font-size 14px
	font-weight light
	#footer-credit
		size $content-width, auto
		centered-box $content-width, auto
		text-align center
		font-weight light


spfsl = 3vmin
spfsm = 1.8vmin
spfss = 1.2vmin
body.sp
	#page
		#pagecatch
			#catch-info
				#catch-info-container
					#catch-info-right
						.catch-copy
							margin-top 180px
		#pagemain
			section
				min-height: 100vh
				.main-content
					width: auto
					margin-left: (spfsm * 5 / 3)vmin!important
					margin-right: (spfsm * 5 / 3)vmin!important
			#main-story
				font-size spfsm
				// line-height 1.5em
			#main-cast
			#main-staff
				.main-content
					img
						size 100vmin, auto
			#main-schedule
				font-size spfsm
				.main-schedule-access-description
					font-size spfss
				.main-schedule-timetable
					width: auto
					tbody
						tr
							height (spfsm * 5 / 2)vmin
							td,th
								padding 0 (spfsm * 5 / 3)vmin
				.seat-stock-description
					margin-left (spfsm * 5 / 3)vmin
					font-size spfss
			#main-ticket
				font-size spfss
			#main-special
				font-size spfsm
		#pagefooter
			height spfsl
			font-size spfsl
			line-height spfsl

#colophon
	fixed: bottom right left
	background-color: rgba(255,255,255,0.85)
	text-align: center
	.page-footer-pagetitle
		font-set: 13px $font-regular black
	.page-footer-copyright
		font-set: 10px $font-light #666666
		clearfix()
