// font-weight names
$font-thin      = 100
$font-light     = 200
$font-delight   = 300
$font-regular   = 400
$font-medium    = 500
$font-bold      = 700
// $font-light     = 400//200
// $font-delight   = 500//300
// $font-regular   = 600//400
// $font-medium    = 700//500
// $font-bold      = 800//700
$font-black     = 900
$font-demilight = $font-delight

// Noto Sans
gwebfont(family, file, weight = 400)
	@font-face
		font-family family
		src url('//fonts.gstatic.com/ea/notosansjapanese/v3/' + file + '.woff2') format('woff2')
		  , url('//fonts.gstatic.com/ea/notosansjapanese/v3/' + file + '.woff') format('woff')
		  , url('//fonts.gstatic.com/ea/notosansjapanese/v3/' + file + '.otf') format('opentype')
		font-weight weight
		// font-style normal

$gwebfont('Noto Sans Japanese', 'NotoSansJP-Thin', $font-thin)
gwebfont('Noto Sans Japanese', 'NotoSansJP-Light', $font-light) //200
gwebfont('Noto Sans Japanese', 'NotoSansJP-DemiLight', $font-delight)//300
gwebfont('Noto Sans Japanese', 'NotoSansJP-Regular', $font-regular)//400
gwebfont('Noto Sans Japanese', 'NotoSansJP-Medium', $font-medium)//500
gwebfont('Noto Sans Japanese', 'NotoSansJP-Bold', $font-bold)//700
gwebfont('Noto Sans Japanese', 'NotoSansJP-Black', $font-black)


html
	// font-family 'caviar_dreams'
	font-family 'Noto Sans Japanese', 'Noto Sans CJK', 'Noto Sans JP 細字'
	          , '游ゴシック体', '游ゴシック', 'Yu Gothic', YuGothic
	          , 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN'
	          , "TakaoEXゴシック", TakaoExGothic
	          , "VL Pゴシック","VL PGothic"
	          , "IPAexゴシック", IPAexGothic, "IPA Pゴシック", IPAPGothic
	          , 'メイリオ', Meiryo
	          , Helvetica
	          ,'ＭＳ Ｐゴシック', sans-serif, sans
	font-style normal
	font-weight normal

.text-gothic
	font-family '游ゴシック体', '游ゴシック', 'Yu Gothic', YuGothic
	          , 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN'
	          , "TakaoEXゴシック", TakaoExGothic
	          , "VL Pゴシック","VL PGothic"
	          , "IPAexゴシック", IPAexGothic, "IPA Pゴシック", IPAPGothic
						, 'Noto Sans Japanese', 'Noto Sans CJK', 'Noto Sans JP 細字'
	          , 'メイリオ', Meiryo
	          , Helvetica
	          ,'ＭＳ Ｐゴシック', sans-serif, sans
	font-weight: $font-medium


.text-mincho
	font-family: '游明朝体', '游明朝', 'Yu Mincho', YuMincho
	           , 'ヒラギノ明朝 ProN W3', 'HiraMinProN-W3'
	           , "IPAex明朝", IPAexMincho, "IPA P明朝", IPAPMincho
	           , "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "Times New Roman", serif;

.text-slim
	display: inline-block
	transform: scaleX(0.75)
	transform-origin: left
